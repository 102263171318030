.pl-28per_company {
  padding-left: 7.3em;
  padding-top: 6.4375rem;
  @media only screen and (min-width: 2000px) {
    padding-left: 32.31%;
  }
}
.left_section_company {
  min-width: max(375px, 22.395%);
}
.bg_image {
  background: rgba(37, 55, 70, 0.8);
  height: 100%;
  width: 100%;
}
.company_overview {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  margin-left: 1.5em;
  color: #253746;
}
.team {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  margin-left: 1.3em;
  color: #253746;
}
.right_section_text_company {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 120%;
  color: #253746;
  padding-bottom: 1em;
}
.right_section_text_company_vijay {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 120%;
  color: #253746;
  padding-bottom: 1em;
}
.right_section_company {
  /* padding-top: 6.4375em; */
  /* padding-bottom: 3em; */
  /* padding-left: 1em; */
  /* min-width: 200px; */
  min-width: 34.11vw;
  max-width: 34.11vw;
}
.right_section_company_vijay {
  padding-top: 6.4375em;
  /* padding-bottom: 3em; */
  /* padding-left: 3.4em; */
  /* min-width: 200px; */
  /* width: 24%; */
}
.right_section_subtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #5b6770;
  width: min(72ch, 100%);
}
.rulo_graphic {
  aspect-ratio: 781 / 744;
  /* height: 46.5em; */
  width: 100%;
  overflow: hidden;
  padding-right: 1em;
}
.company_banner::before {
  position: absolute;
  inset: 0;
  height: 100%;
  background-color: rgba(37, 55, 70);
  opacity: 0.8;
  z-index: -1;
  left: 0px;
  content: "";
}
.company_banner {
  position: relative;
  height: 100%;
  background-image: url("../../assets/Company/company_bgimage.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 59% 30%;
  place-items: center;
  z-index: 0;
  padding: 10.4em 0;
  padding-left: 10em;
}
.banner_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 100%;
  padding-bottom: 0.5em;
  color: #ffffff;
  width: 17ch;
}
.banner_text1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%;
  color: #ffffff;
  width: min(52ch, 90%);
}
.analytics_text {
  color: #ffffff;
  font-size: inherit;
}
.industrial_text {
  color: #ff6900;
  font-size: inherit;
}
.vijay_img {
  border-radius: 50%;
  background: #f1f3f4;
  display: block;
  width: 15.62vw;
}
.vijay_txt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 120%;
  color: #253746;
  padding-bottom: 1em;
}
.vijay_designation {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 120%;
  color: #ff6900;
  padding-bottom: 1em;
}
.vijay_icon_div {
  display: flex;
  justify-content: space-between;
}
.about_vijay {
  width: 91ch;
}
.right_item1 {
  padding-left: 1em;
}
.right_section_item {
  padding-top: 6.4375em;
  /* padding-bottom: 3em; */
  padding-left: 6em;
  /* min-width: 200px; */
  /* width: 41.14%; */
}
.full_right_side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.team_right_side {
  padding-bottom: 3em;
}
.linkedin_icon {
  padding-right: 1.5em;
}
.rulo_triangle {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 781/744;
  /* margin-top: -2em; */
  padding-right: 2em;
  display: flex;
  align-items: center;
  /* background-image: url("../../assets/Company/rulo_graphic.svg");
  background-size: cover;
  background-position: 50% 50%; */
}
.grid_item_1 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  overflow: hidden;
  /* max-height: 30vw; */
}
.grid_item_2 {
  background: #f1f3f4;
  display: flex;
  flex-direction: row;
  padding-right: 15.36vw;
}
.for_span {
  font-size: inherit;
}

@media (max-width: 850px) {
  .full_right_side {
    display: flex;
    flex-direction: column;
  }
  .vijay_icon_div {
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
  }
  .about_vijay {
    display: inline-block;
    width: auto;
    padding-right: 2em;
  }
  .vijay_img {
    padding-bottom: 0em;
    width: 5.5em;
  }
  .grid_item_1 {
    flex-direction: column;
    max-height: none;
    overflow: visible;
    padding-right: 1em;
  }
  .grid_item_2 {
    display: flex;
    flex-direction: column;
    padding-right: 1em;
  }
  .left_section_company {
    min-width: 100%;
  }
  .pl-28per_company {
    padding-left: 1.25em;
    padding-top: 2.5em;
  }
  .right_section_item {
    padding-left: 1.25em;
    padding-top: 1.6em;
  }
  .right_section_subtext {
    width: 100%;
    padding-right: 1em;
    padding-bottom: 2em;
  }
  .right_section_company {
    width: auto;
    padding-top: 3em;
    padding-left: 1em;
    max-width: 100%;
  }
  .right_section_company_vijay {
    padding-left: 1em;
    padding-top: 0.9em;
  }
  .right_section_text_company {
    width: 100%;
    padding-top: 1.6em;
  }
  .right_section_text_company_vijay {
    /* width: 100%; */
    padding-top: 1.6em;
  }
  .company_banner {
    padding-left: 1.125em;
    padding-top: 4em;
    padding-bottom: 4em;
    padding-right: 1em;
  }
  .rulo_triangle {
    height: 24em;
    padding-top: 4em;
    padding-left: 1.125em;
    display: flex;
    justify-content: center;
    background: none;
    width: auto;
  }
  .rulo_graphic {
    aspect-ratio: 335 / 319;
    height: 19.9em;
    width: 83.67vw;
    padding-right: 1em;
  }
  .right_item1 {
    padding-left: 0;
  }
  .for_span {
    color: #307fe2;
  }
  .banner_text {
    font-size: 2.25rem;
    width: 100%;
  }
  .banner_text1 {
    font-size: 1rem;
    width: 100%;
  }
}
@media (min-width: 450px) and (max-width: 850px) {
  .right_section_subtext {
    width: auto;
  }
}
@media only screen and (min-width: 851px) and (max-width: 1200px) {
  .pl-28per_company {
    padding-left: 31.31%;
  }
  .rulo_triangle {
    width: calc((30.67vw) * 755 / 744);
    padding-right: 10em;
  }
  .grid_item_1 {
    max-height: 25vw;
  }
  .right_section_text_company {
    font-size: 1.2rem;
  }
  .right_section_text_company_vijay {
    font-size: 1.2rem;
  }
  .right_section_subtext {
    font-size: 0.6rem;
  }
}
@media only screen and (min-width: 851px) and (max-width: 1300px) {
  .grid_item_1 {
    /* max-height: 50vw; */
  }
}
@media only screen and (min-width: 851px) and (max-width: 1275px) {
  .about_vijay {
    width: auto;
  }
}
@media only screen and (min-width: 1900px) {
  .right_section_company_vijay {
    padding-left: 3.4em;
  }
}

/* @media only screen and (min-width: 851px) and (max-width: 1200px) {
  * {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 2000px) {
  * {
    font-size: 16px;
  }
} */
/* * {
  border: 1px solid red;
} */
