.left_section_platform {
  width: max(375px, 22.395%);
}

.pl-28per-platform {
  padding-left: 10rem;
  padding-top: 7.5rem;
}

.pl-28per-platform div {
  margin-left: -2.25rem;
}

.heading_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #253746;
  margin-left: 1rem;
}

.works_background {
  background: #f1f3f4;
  padding-right: 8.3vw;
}

.background {
  background: #253746;
}

.center_section {
  padding: 7.5rem 0;
  padding-left: 22.3vw;
  padding-right: 3.5vw;
  width: 43.54vw;
}

.center_section_works {
  padding-left: 22.3vw;
  padding-top: 2.1875rem;
  padding-bottom: 10.5rem;
  padding-right: 2.7vw;
  width: 46.35vw;
}
.center_section_footer_works {
  padding-top: 2.1875rem;
  padding-left: 22.3vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
}

.right_section_platform {
  padding-bottom: 7.3125rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 46.3%;
}

.right_section_platform_works {
  padding-bottom: 8.3125rem;
  flex-grow: 1;
  /* padding-top: 8.0625rem; */
}
.right_section_platform_footer_works {
  padding-bottom: 3.75rem;
  flex-grow: 1;
}

.right_section_table {
  padding-right: 9.8125rem;
  /* padding-top: 7.781rem; */
  padding-left: 22.6vw;
  padding-bottom: 6.15625rem;
}

.center_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 120%;
  color: #253746;
  /* align-items: center; */
}
.text_color_blue {
  color: #307fe2;
}

.right_text_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  color: #253746;
  margin-top: 0.625rem;
}

.right_sub_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #5b6770;
  padding-top: 1.9375rem;
  width: 43ch;
}

.right_sub_text_monitor {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #5b6770;
  padding-top: 1.9375rem;
  width: 39ch;
}

.platform_works_dot_tabs {
  margin-top: 2.815rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.mt-3em {
  margin-top: 3.625em;
}

.table_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.87rem;
  line-height: 120%;
  color: #000000;
  text-align: center;
}

.sub_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 120%;
  margin-top: 0.75rem;
  color: #a9a9a9;
  text-align: center;
  margin-bottom: 1.28125rem;
  /* padding-left: 4.16vw; */
  width: 20ch;
}
.table_feature {
  font-family: "Lato" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  line-height: 130% !important;
  color: #000000;
  padding: 1rem;
}
.disable_txt {
  /* color: #ddd; */
  opacity: 0.25;
}

.align_center {
  text-align: center;
}
.table_row_even {
  background: #f9f9fb;
}
.table_row_even th {
  background: #f9f9fb;
}
.table_row_odd th {
  background: white;
}
.product_sheet_btn {
  background: #ff6900;
  border: 1px solid #f36d21;
  border-radius: 4px;
  padding: 0.5em;
  margin-top: 1.78125rem;
}
.product_sheet_btn_txt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #ffffff;
  margin: 1.5em 1.56em;
}
.disable_btn {
  opacity: 0.5;
}
.flex_display {
  display: flex;
}
.platform_banner_grid {
  display: grid;
  grid-template-areas: "one two" "three two";
  z-index: 2;
  padding-left: 10em;
  border-top: 1px solid #5b6770;
  overflow: hidden;
}
.platform-banner-grid-item-1 {
  grid-area: one;
}
.platform-banner-grid-item-2 {
  grid-area: two;
}
.platform-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 100%;
  color: #ffffff;
  /* padding-top: 11.25rem; */
  padding-bottom: 1rem;
  width: 20ch;
}
.platform-banner-grid-item-1 .platform-title span {
  font-size: inherit;
  color: #ff6900;
}
.platform-banner-grid-item-1 p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%;
  color: white;
  width: 52ch;
}
.platform-banner-grid-item-3 div p span {
  font-size: 2rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: #ffffff;
}
.platform-banner-grid-item-3 p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 150%;
  color: white;
  width: 54ch;
}
.short_text {
  font-weight: 400 !important;
  font-size: 1.5rem !important;
  line-height: 150% !important;
}
.platform_banner_ipad {
  width: 52.63vw;
}
.platform_banner_4s {
  position: absolute;
  z-index: 100;
  inset: 0px;
  left: 57.2%;
  top: 52.94%;
  width: 27.86%;
}
.custom_banner_4n {
  width: 24.19%;
  left: 59.2%;
  top: 54.94%;
}
.custom_banner_4l {
  width: 41.135%;
  left: 50.04%;
  top: 58.32%;
}
.custom_banner_4m {
  width: 19.75%;
  top: 57.94%;
  left: 61.2%;
}
.platform_banner_iphone {
  position: absolute;
  z-index: 100;
  inset: 0px;
  top: 35.38%;
  left: 14.41%;
  width: 13%;
}
.platform-banner-grid-item-3 {
  grid-area: three;
  padding-top: 3.6rem;
}
.platform-banner-button-pd {
  padding-right: 6.5em !important;
}
.transparent-orangeBrder-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;

  background: transparent;
  border: 1px solid #f36d21;
  border-radius: 0.25em;
  color: #ff6900;
  padding: 0.25em 0.75em;
  margin-left: 1.5rem;
}
.img_width {
  width: 100%;
}
.features_data_mobile {
  display: none;
}
.features_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  color: #253746;
  padding-top: 2em;
  padding-left: 4.5%;
}
.features_sub_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: #5b6770;
  padding-left: 4.5%;
  padding-top: 1rem;
}
.features_includes {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 120%;
  color: #5b6770;
  padding-left: 4.5%;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
.features_checkbox {
  padding-left: 4.5%;
  padding-bottom: 0.5rem;
}
.features_list {
  padding-left: 0.25rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: #5b6770;
}
.btn-padding {
  padding: 1.125rem 0;
}
.ml-4per {
  margin-left: 4.5%;
}
.platform-banner-button-text-below {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.75);
  /* margin-bottom: 7rem; */
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.margin_0 {
  margin: 0;
}

.platform_tabs {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  border-top: 1px solid #5b6770;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 5;
  isolation: isolate;
  margin-left: 10em;
  margin-right: 6rem;
  padding-bottom: 2em;
  cursor: pointer;
  margin-top: 2.5rem;
  color: rgba(255, 255, 255, 0.5);
}
.platform-tab-index {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 100%;
  padding: 1.0625rem 0;
}
.platform-tab-names {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 100%;
}
.active-tab {
  color: #ffffff;
  border-top: 1px solid #ff6900;
}
.platform_flex_column {
  display: flex;
  flex-direction: column;
}
.platform_image_transform {
  transform: scale(1.125, 1.125);
}

table {
  table-layout: fixed;
}

table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: 16.5rem;
}

table tbody th {
  position: relative;
}
table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
}
.table_outlines {
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 3.375rem;
}
.table_outlines::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.table_outlines::-webkit-scrollbar {
  height: 0.25rem;
  background-color: #f5f5f5;
}

.table_outlines::-webkit-scrollbar-thumb {
  background-color: #ff6900;
}
.platform_mobile_view {
  display: none;
}

@media only screen and (max-width: 850px) {
  .platform_mobile_view {
    display: block;
    margin-left: 1.125em;
    margin-right: 1.125em;
  }
  .right_section_table {
    display: none;
  }
  .pl-28per-platform {
    padding-left: 1.125rem !important;
    padding-top: 2.625rem;
  }
  .pl-28per-platform div {
    margin-left: 0;
  }
  .center_section {
    padding: 2.25em 0;
    padding-left: 4.5%;
    width: auto;
  }
  .center_section_works {
    padding-left: 1.125rem;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 2rem;
    width: auto;
  }
  .center_section_footer_works {
    display: none;
  }
  .center_text {
    font-size: 1.5rem;
    line-height: 100%;
  }
  .flex_display {
    display: block;
  }
  .right_section_platform {
    padding-left: 1.125rem;
    padding-right: 0;
    padding-bottom: 2rem;
    display: block;
    width: auto;
  }
  .right_sub_text {
    width: auto;
  }
  .right_sub_text_monitor {
    width: auto;
  }
  .mq-mt-3em {
    margin-top: 3.625em;
  }
  .img_width {
    width: 100%;
  }
  .right_section_platform_works {
    padding: 2em 5em 2em 1em;
  }
  .right_section_platform_footer_works {
    padding: 2em 5em 2em 1em;
  }
  .flex_display_xs {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .platform_banner_grid {
    display: none;
    /* padding-left: 1em;
        padding-right:1em;
        grid-template-areas: 'one''two''three'; */
  }
  .platform-title {
    padding-top: 1em;
    padding-right: 1em;
    width: auto;
    font-size: 3rem;
  }
  .platform-banner-grid-item-1 p {
    width: auto;
    margin-top: 0;
    font-size: 1rem;
    padding-right: 3em;
  }
  .platform_banner_ipad {
    width: 100%;
    height: auto;
  }
  .platform-banner-grid-item-3 div p span {
    font-size: 1.5rem;
  }
  .platform-banner-grid-item-3 p {
    width: auto;
  }
  .short_text {
    font-size: 1rem !important;
  }
  .hide-mobile {
    display: none;
  }
  .right_text_heading {
    font-size: 1.25rem;
  }
  .platform_banner_iphone {
    top: 35.42%;
    left: 15%;
    width: 12.62%;
  }
  .platform_banner_4s {
    left: 58.77%;
    top: 56.8%;
    width: 28.25%;
  }
  .custom_banner_4m {
    width: 24%;
    top: 60%;
  }
  .custom_banner_4s {
    width: 32.75%;
    top: 53.5%;
  }
  .custom_banner_4l {
    top: 62%;
  }
  .features_data_mobile {
    display: block;
    padding-bottom: 4.25rem;
  }
  .platform_tabs {
    display: none;
  }
  .platform-banner-button-text-below {
    padding-bottom: 2.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .right_section_platform_works {
    padding: 2em 2em 2em 2em;
  }
  .right_section_platform_footer_works {
    padding: 2em 2em 2em 2em;
  }
  .center_section_works {
    padding-right: 2em;
  }
  .center_section_footer_works {
    display: none;
  }
  .platform_tabs {
    display: none;
  }
  .platform-banner-button-text-below {
    padding-bottom: 2.25rem;
  }
}
@media only screen and (min-width: 851px) and (max-width: 1200px) {
  .pl-28per-platform {
    padding-left: 22.31% !important;
  }
  .platform-title {
    font-size: 3rem;
  }
  .platform_banner_grid {
    padding-left: 10em;
  }
  .platform-banner-grid-item-1 p {
    font-size: 1rem;
  }
  .short_text {
    font-size: 1rem !important;
  }
  .center_section {
    padding-left: 20em;
  }
  .center_text {
    font-size: 1.75rem;
  }
  .right_text_heading {
    font-size: 1.25rem;
  }
  .right_sub_text {
    width: 31ch;
  }
  .right_sub_text_monitor {
    width: 31ch;
  }
  .center_section_works {
    padding-left: 20em;
  }
  .center_section_footer_works {
    padding-left: 20em;
  }
  .right_section_table {
    padding-left: 20em;
    padding-right: 3em;
  }
  .right_section_platform_works {
    padding-left: 1em;
    padding-right: 2em;
  }
  .right_section_platform_footer_works {
    padding-left: 1em;
    padding-right: 2em;
  }
  .platform-banner-button-text-below {
    padding-bottom: 2.25rem;
  }
  .platform_image_transform {
    transform: scale(1.125, 1.125) translateX(-8vw);
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .platform_image_transform {
    transform: scale(1.125, 1.125) translateX(-7vw);
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .platform_image_transform {
    transform: scale(1.125, 1.125) translateX(-5vw);
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .platform_image_transform {
    transform: scale(1.125, 1.125) translateX(-7vw);
  }
}

@media only screen and (min-width: 2000px) {
  .platform_image_transform {
    transform: scale(1.125, 1.125) translateX(-5vw);
  }
  .center_section_footer_works {
    font-size: 1rem;
  }
}
