.pl-28per_company {
  padding-left: 7.3em;
  padding-top: 6.4375rem;
}
.left_section_company {
  min-width: max(375px, 22.395%);
}
.left_dots {
  width: 1rem;
  margin-right: 1.3em;
}
.industry_banner::before {
  position: absolute;
  inset: 0;
  height: 100%;
  background-color: rgba(37, 55, 70);
  opacity: 0.8;
  z-index: -1;
  left: 0px;
  content: "";
}
.industry_banner {
  position: relative;
  height: 100%;
  background-image: url("../../assets/Industry/industry_bgimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 59% 30%;
  place-items: center;
  z-index: 0;
  padding-top: 10.6em;
  padding-bottom: 10.6em;
  padding-left: 10em;
}
.banner_text_industry {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 100%;
  padding-bottom: 0.4em;
  color: #ffffff;
  width: 17ch;
}
.banner_text1_industry {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%;
  color: #ffffff;
  width: min(55ch, 90%);
}
.analytics_text {
  color: #ffffff;
  font-size: inherit;
}
.industrial_text {
  color: #ff6900;
  font-size: inherit;
}
.all_industry_tiles_mobile {
  display: none;
}
.industry_tile_title_mobile {
  display: none;
}
.industry_flex_row {
  display: flex;
  flex-direction: row;
  padding-top: 7.5rem;
  padding-bottom: 5.9375rem;
  overflow: hidden;
}
.industry_left_section {
  min-width: max(375px, 22.395vw);
}

.industry_left_section_second_row {
  margin-left: 2.25rem;
  margin-top: 1rem;
}
.left_section_industry {
  display: flex;
  flex-direction: column;
  padding-left: 28.31%;
}
.industry_left_sec_header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #ffffff;
}
.industry_parent {
  flex-grow: 1;
  margin-right: 9.875rem;
  overflow-x: scroll;
}
.industry_overflow_hidden {
  overflow-x: hidden;
  opacity: 0.5;
}
.pointer-events-none {
  pointer-events: none;
}
.industry_parent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.industry_parent::-webkit-scrollbar {
  height: 0.25rem;
  background-color: #f5f5f5;
  width: 16rem;
}

.industry_parent::-webkit-scrollbar-thumb {
  background-color: #ff6900;
}
.industry_tile {
  background-repeat: no-repeat;
  cursor: pointer;
  /* background-image: var(--bg-image); */
  aspect-ratio: 385/455;
  height: 28.4375rem;
  border-radius: 0px 0px 1.25rem 0px;
  background-size: cover;
  padding-top: 1.5rem;
  padding-left: 1.875rem;
  padding-right: 2.25rem;
  position: relative;
  z-index: 1;
  margin-bottom: 1.3125rem;
}

.industry_tile::after {
  content: "";
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #253746 0%,
    #253746 0.01%,
    rgba(37, 55, 70, 0) 100%
  );
  z-index: -2;
}

.industry_tile::before {
  position: absolute;
  inset: 0px;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #307fe2 49.48%,
    rgba(48, 127, 226, 0) 100%
  );
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
}

.industry_tile_mobile {
  display: none;
  /* background-repeat: no-repeat; */
  /* background-image: var(--bg-image); */
  /* aspect-ratio: 385/455;
    height: 28.4375rem;
    border-radius: 0px 0px 1.25rem 0px;
    background-size: cover;
    padding-top: 1.5rem;
    padding-left: 1.875rem;
    padding-right: 2.25rem;
    position: relative;
    z-index: 1;
    margin-bottom: 1.3125rem; */
}

.industry_tile_mobile::after {
  display: none;
  /* content: "";
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      #253746 0%,
      #253746 0.01%,
      rgba(37, 55, 70, 0) 100%
    );
    z-index: -2; */
}

.industry_tile_mobile::before {
  display: none;
  /* position: absolute;
    inset: 0px;
    width: 100%;
    content: "";
    z-index: -1;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      #307fe2 49.48%,
      rgba(48, 127, 226, 0) 100%
    );
    opacity: 0;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s; */
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.full_industry_tiles_mobile {
  display: none;
}

.industry_tile_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 100%;
  padding-top: 6.25rem;
  padding-bottom: 1rem;

  color: #ffffff;
}

.industry_tile_desc {
  opacity: 0;
  transition: all ease 0.5;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */

  color: #ffffff;
  transition: all 0.5s ease;
}

/* .industry_tile:hover::before {
  opacity: 1;
  height: 100%;
}

.industry_tile:hover .home_industry_tile_desc {
  opacity: 1;
} */
.custom_rulo_text_div {
  padding-top: 14em;
  padding-left: 2.3em;
}
.custom_rulo_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #ff6900;
}
.specific_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 150%;
  color: #ffffff;
  width: 28ch;
}
.blue-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  background: #253746;
  border: 1px solid #253746;
  border-radius: 0.25em;
  color: white;
  padding: 0.25em 0.75em 0.25em 0.3px;
}
.full_industry_tiles {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}
.description_industry_tile {
  height: 28.4375rem;
  aspect-ratio: 385/455;
  border-radius: 0px 0px 1.25rem 0px;
  padding-top: 1.5rem;
  padding-left: 1.875rem;
  padding-right: 2.25rem;
  position: relative;
  z-index: 1;
  margin-bottom: 1.3125rem;
  background: #307fe2;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
}
.description_industry_tile_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 100%;
  padding-bottom: 1rem;
  color: #ffffff;
}
.descriptive_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #ffffff;
}
.close_icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  background: #253746;
}
.usecase_div {
  margin-left: 1.25rem;
  display: flex;
}
.usecase_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #5b6770;
  padding-bottom: 1.25em;
  padding-top: 2em;
  width: 9.9vw;
}
.usecases {
  background: #ffffff;
  height: 28.4375rem;
  padding-right: 1.5em;
  padding-left: 1.75em;
  width: 13.958vw;
}
.usecase_options {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #000000;
}
.industry_tile_clicked_div {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 10;
  left: 26.875em;
}
.usecase_image_div {
  position: relative;
  height: 28.4375rem;
}
.desktop_image {
  height: 100%;
}
.orange_div {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 20vw;
  background: #ff6900;
  padding-right: 1.25em;
}
.rulo_text_industry {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #ffffff;
  padding-left: 1.25em;
  padding-top: 1em;
  padding-bottom: 1.375em;
}
.casestudy_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 100%;
  color: #ffffff;
  padding-left: 0.75em;
  padding-bottom: 0.35em;
  cursor: pointer;
}
.arrow_icon {
  padding-bottom: 1.5em;
}
.arrow_icon_mobile {
  cursor: pointer;
}
.div_containing_clickable_text_mobile {
  display: flex;
  padding-bottom: 1.25em;
}
.home_left_section_second_row {
  margin: 0px;
}

@media (max-width: 850px) {
  .left_dots {
    display: none;
  }
  .industry_flex_row {
    flex-wrap: wrap;
    padding-top: 1.25em;
    padding-bottom: 2.3125em;
  }
  .industry_left_section {
    min-width: 100%;
  }
  .left_section_industry {
    padding-left: 0px;
  }
  .home_left_section_second_row {
    margin-left: 2.25rem;
    margin-top: 1rem;
  }
  .left_section_company {
    min-width: 100%;
  }
  .pl-28per_company {
    padding-left: 1.25em;
    padding-top: 2.5em;
  }
  .industry_banner {
    padding-left: 1.125em;
    padding-top: 4em;
    padding-bottom: 4em;
    padding-right: 1em;
  }
  .banner_text_industry {
    font-size: 2.5rem;
    width: 100%;
  }
  .banner_text1_industry {
    font-size: 1rem;
  }
  .industry_left_sec_header {
    padding-left: 1.125em;
    padding-bottom: 0.633125em;
    width: 100%;
  }
  .all_industry_tiles_mobile {
    display: flex;
    flex-direction: column;
  }
  .industry_tile_mobile {
    display: block;
    background-repeat: no-repeat;
    /* background-image: var(--bg-image); */
    /* aspect-ratio: 375/179; */
    width: 100%;
    height: 11.1875rem;
    background-size: cover;
    padding-top: 1.25rem;
    padding-left: 1.125rem;
    padding-right: 0rem;
    position: relative;
    z-index: 1;
    margin-bottom: 1.3125rem;
  }

  .industry_tile_mobile::after {
    display: block;
    content: "";
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      #253746 0%,
      #253746 0.01%,
      rgba(37, 55, 70, 0) 100%
    );
    z-index: -2;
  }

  .industry_tile_mobile::before {
    display: block;
    position: absolute;
    inset: 0px;
    width: 100%;
    content: "";
    z-index: -1;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      #307fe2 49.48%,
      rgba(48, 127, 226, 0) 100%
    );
    opacity: 0;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
  }
  .industry_tile_title_mobile {
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 100%;
    padding-bottom: 1rem;
    color: #ffffff;
  }
  .full_industry_tiles_mobile {
    display: block;
    gap: 1.25rem;
  }
  .custom_rulo_text_div {
    padding-top: 3.375em;
    padding-left: 1.125em;
  }
  .industry_parent {
    display: none;
  }
  .img_icon {
    height: 4.375rem;
    padding-bottom: 1.25em;
  }
  .industry_tile_clicked_div {
    display: none;
  }
  .industry_tile_clicked_div_mobile {
    display: flex;
    flex-direction: column;
    position: relative;
    /* z-index: 10; */
  }
  .description_industry_tile_mobile {
    /* height: 28.4375rem;
    aspect-ratio: 385/455;
    border-radius: 0px 0px 1.25rem 0px; */
    padding-top: 1.5rem;
    padding-left: 1.25rem;
    padding-right: 2.25rem;
    position: relative;
    z-index: 1;
    /* margin-bottom: 1.3125rem; */
    background: #307fe2;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  }
  .description_industry_tile_title_mobile {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 100%;
    padding-bottom: 1rem;
    color: #ffffff;
  }
  .descriptive_text_mobile {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #ffffff;
    padding-top: 2.25em;
  }
  .image_mobile_view {
    /* aspect-ratio: 370/330; */
    /* height: 20.625rem; */
    width: 100%;
  }
  .image_div_tile_clicked_mobile {
    /* aspect-ratio: 370/330;
    height: 20.625rem; */
    width: 100%;
  }
  .usecase_div_mobile {
    background: #ffffff;
    height: 22.1875rem;
    width: 100%;
    /* padding-right: 1.5em; */
    padding-left: 1.25em;
  }
  .usecase_options_mobile {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: #000000;
  }
  .usecase_heading_mobile {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #5b6770;
    padding-bottom: 1.25em;
    padding-top: 2em;
    width: 100%;
  }
  .orange_div_mobile {
    width: 100%;
    background: #ff6900;
    padding-right: 1.25em;
  }
  .orange_div_text_mobile {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: #ffffff;
    padding-left: 1em;
    cursor: pointer;
  }
  .rulo_text_industry_mobile {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: #ffffff;
    padding-left: 1.25em;
    padding-top: 1.25em;
    padding-bottom: 1.375em;
  }
  .desc_icon_div_mobile {
    padding-top: 2.0625em;
    padding-bottom: 1.6875em;
  }
}
@media only screen and (min-width: 850px) and (max-width: 1300px) {
  .usecases {
    width: 9.958vw;
  }
  .desktop_image {
    width: 32vw;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .industry_tile_clicked_div {
    left: 31.2em;
  }
  .usecases {
    width: 11vw;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1750px) {
  .industry_tile_clicked_div {
    left: 23.5em;
  }
  .usecases {
    width: 11.5vw;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1650px) {
  .desktop_image {
    width: 35vw;
  }
}
@media only screen and (min-width: 2501px) and (max-width: 2530px) {
  .desktop_image {
    width: 34vw;
  }
}
@media only screen and (min-width: 2001px) and (max-width: 2030px) {
  .desktop_image {
    width: 33vw !important;
  }
}
@media only screen and (min-width: 1751px) and (max-width: 1850px) {
  .industry_tile_clicked_div {
    left: 24.5em;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 2100px) {
  .industry_tile_clicked_div {
    left: 18.5em;
  }
}
@media only screen and (min-width: 2101px) and (max-width: 2300px) {
  .industry_tile_clicked_div {
    left: 20.5em;
  }
}
@media only screen and (min-width: 2301px) and (max-width: 2400px) {
  .industry_tile_clicked_div {
    left: 21.5em;
  }
}
@media only screen and (min-width: 2401px) and (max-width: 2600px) {
  .industry_tile_clicked_div {
    left: 23.875em;
  }
}
@media only screen and (min-width: 2601px) and (max-width: 2800px) {
  .industry_tile_clicked_div {
    left: 25.75em;
  }
}
@media only screen and (min-width: 2001px) and (max-width: 2500px) {
  .desktop_image {
    width: 34vw;
    object-fit: cover;
    object-position: 10%;
  }
}
/*
@media only screen and (min-width: 2000px) and (max-width: 2350px) {
  .industry_tile_clicked_div {
    left: 20.875em;
  }
}
@media only screen and (min-width: 2351px) {
  .industry_tile_clicked_div {
    left: 31.875em;
  } 
}*/
@media only screen and (min-width: 2000px) {
  .specific_text {
    width: 26ch;
  }
}
