.benefits_banner::after {
  position: absolute;
  inset: 0;
  height: 100%;
  background-color: rgba(37, 55, 70);
  opacity: 0.8;
  z-index: -1;
  left: 0px;
  content: "";
}

.benefits_banner {
  position: relative;
  background-image: url("../../assets/Benefits/benefits_banner_bg_img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 59% 30%;
  z-index: 1;
  padding-left: 10rem;
}

.benefits_banner_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 100%;
  color: #ffffff;
  width: 55.375rem;
  padding-top: 10.875rem;
  padding-bottom: 1rem;
}

.benefits_banner_title span {
  color: #ff6900;
  font-size: inherit;
}

.benefits_banner_desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%;
  color: #ffffff;
  padding-bottom: 10.875rem;
  width: 46.375rem;
}

.benefits_flex_row {
  display: flex;
  flex-direction: row;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.benefits_left_section {
  min-width: max(375px, 22.395vw);
}

.left_section_benefits {
  display: flex;
  flex-direction: column;
  padding-left: 28.31%;
}

.benefits_left_sec_header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;

  color: #253746;
  /* margin-left: 1.3em; */
}

.benefits_left_section_second_row {
  margin-left: 2.25rem;
  margin-top: 1.5rem;
}

.left_section_sub_text_benefits {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #5b6770;
  padding: 0.25em 0;
  padding-left: 1.25rem;
  border-left: 1px solid #5b6770;
  height: 100%;
  cursor: pointer;

  /* margin-right: 0.5rem; */
  /* margin-top: 1rem; */
}

.selected_section {
  border-left: 2px solid #ff6900;
  font-weight: 500;
  color: #253746;
}

.benefits_parent {
  flex-grow: 1;

  overflow: auto;
}

.benefits_grid {
  display: grid;
  grid-template-areas: "two one";
}
.benefits_grid_reverse {
  display: grid;
  grid-template-areas: "one two";
}
.benefits_grid_item_1 {
  grid-area: one;
}

.benefits_grid_item_2 {
  grid-area: two;
}

.benefits_text_content {
  max-width: 27.24vw;
  margin-right: 9.75rem;
}

#sec1 {
  padding-bottom: 7.5rem;
  scroll-margin: 30vh;
}
#sec2 {
  padding-bottom: 7.5rem;
  scroll-margin: 30vh;
}
#sec3 {
  padding-bottom: 7.5rem;
  scroll-margin: 30vh;
}
.benefits_text_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 120%;
  color: #253746;
  margin-bottom: 0.75rem;
}

.benefits_text_title span {
  font-size: inherit;
  color: #307fe2;
}
.benefits_text_sub_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;
  color: #253746;
  margin-bottom: 0.75rem;
}

.benefits_text_desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #5b6770;
}
.benefits_section1_outerdiv {
  width: 100%;
  position: relative;
}
.benefits_section1_overlay_img {
  position: absolute;
  width: 97.1%;
  inset: 0px;
  top: 5.5%;
  left: 2.5%;
}
.benefits_section2_outerdiv {
  position: relative;
  width: fit-content;
}
.benefits_section2_title {
  width: 24.06vw;
}

.benefits_section2_img {
  width: 34.63vw;
}
.benefits_section2_overlay_img {
  position: absolute;
  inset: 0px;
  top: 31.1167%;
  left: 60.49%;
  width: 55.62%;
}
.benefits_section2_text_margins {
  margin-right: 10rem;
  margin-left: 3.3125rem;
}

@media (max-width: 850px) {
  .benefits_banner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-image: url("../../assets/Benefits/benefits_banner_phone_bg_img.png");
    background-position: 71% 101%;
  }

  .benefits_banner_title {
    font-size: 2.25rem;
    width: 100%;
    padding-top: 3.5625rem;
  }

  .benefits_banner_desc {
    width: 100%;
    font-size: 1rem;
    padding-bottom: 2.5rem;
  }

  .benefits_flex_row {
    flex-wrap: wrap;
    padding: 2.5rem 1.25rem;
  }

  .benefits_left_section {
    min-width: 100%;
  }

  .left_section_benefits {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
  }

  .benefits_left_section_second_row {
    margin: 0px;
  }

  .benefits_right_side {
    margin-top: 2rem;
  }

  .benefits_parent {
    margin-right: 0px;
  }
  .benefits_display_none {
    display: none;
  }
  .benefits_grid,
  .benefits_grid_reverse {
    grid-template-areas: "one" "two";
  }
  .benefits_text_content {
    width: 100%;
    max-width: none;
  }
  .benefits_text_title {
    font-size: 2rem;
  }
  .benefits_text_sub_title {
    font-size: 1.125rem;
  }
  .benefits_text_desc {
    font-size: 1rem;
  }
  #sec1,
  #sec2,
  #sec3 {
    padding-bottom: 3.375rem;
  }
  .benefits_section2_overlay_img {
    position: static;
    width: 100%;
  }
  .benefits_section2_img {
    width: 100%;
  }
  .benefits_section2_outerdiv {
    position: static;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .benefits_section2_title {
    width: 100%;
  }
  .benefits_section2_text_margins {
    margin-right: 0;
    margin-left: 0;
  }
  .benefits_section1_overlay_img {
    display: none;
  }
  .benefits_section1_outerdiv img {
    margin-bottom: 2.5rem;
  }
  .home_mobile_outer_div {
    margin-top: 1.125rem;
  }
}
@media (max-width: 1450px) {
  .benefits_section2_text_margins {
    margin-right: 5vw;
  }
}
@media (max-width: 1323px) {
  .benefits_section2_text_margins {
    margin-right: 3vw;
  }
}
@media only screen and (min-width: 500px) and (max-width: 850px) {
  .benefits_banner {
    background-position: 71% 75%;
  }
}
