.contact_banner{
    background: #253746;
    border-top: 1px solid #5B6770;
}
.left_section_contact {
    width: max(375px, 22.395%);
}
.contact_text{
    padding-left: 7.5625rem;
    padding-top: 7.75rem;
}
.contact_title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: #FFFFFF;
    padding-left: 1.1875rem;
}
.contact_heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 100%;
    color: #FFFFFF;
    padding-left: 2.25rem;
    padding-top: 1.125rem;
    padding-bottom: 5rem;
}
.contact_body{
    padding: 5.25rem 10rem 9.375rem;
    background: #F1F3F4;
}

.contact_grid{
    display: grid;
    grid-template-areas: 'one two';
}

.contact_grid_item1{
    grid-area: one;
    width: 41.14vw;
}
.contact_grid_item2{
    grid-area: two;
    padding-left: 15.1vw;
    width:fit-content;
    position: relative;
}

.form_headings{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 100%;
    color: #253746;
}
.form_sections{
    display: flex;
    flex-direction: column;
    width: 48.75%;
}
.form_labels{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    color: #253746;
    padding-bottom: 0.375rem;
}
.form_inputs{
    border: 1px solid rgba(228, 228, 228, 0.6);
    border-bottom: 1px solid;
    padding: 0.5625rem 0;
}
.pt2{
  padding-top:2.3125rem;  
}
.pt1_625{
    padding-top: 1.625rem;
}
.pt1_375{
    padding-top: 1.375rem;
}
.contact_right_box{
    background: #307FE2;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
    padding: 1.835rem 3.73875rem 0.75rem 1.875rem;
    min-width: 32.5rem;
}
.contact_right_terms{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.75);
    padding-top: 2.9375rem;
}
.contact_right_heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 131.25%;
    color: #FFFFFF;
}
.contact_right_subheading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 106.25%;
    color: #FFFFFF;
    padding-top: 1.1975rem;
    padding-bottom: 1.9375rem;
}
.demo_btn_pd{
    padding:0.5rem 3.5rem 0.5625rem 0;
}
.btn_submit{
    background: #253746;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
    border-radius: 4px;
}
.submit_txt{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 131.25%;
    color: #FFFFFF;
    padding: 0.5625rem 7.625rem 0.5625rem 1.3125rem;
}
.pt_4_75{
    padding-top:4.75rem
}
.width_100{
    width:100%;
}
.display_flex{
    display: flex;
    justify-content: space-between;
}
.success_msg{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;   
    font-size: 1rem;
    line-height: 21px;
    color: #FF6900;
}
.pt_1_37{
    padding-top: 1.375rem;
}

/* address section css start */
.rolo-contact-info{position: absolute;bottom: 0;background-color: #253746;display: flex;align-items: center;flex-wrap: wrap;justify-content: space-between;padding: 20px 28px;gap: 15px;width: calc(100% - 15.1vw);}
.rolo-logo-contact p{font-size: 12px;line-height: 17px;font-weight: 400;margin-top: 6px;margin-bottom: 0;color: #fff;max-width: 199px;}
.rolo-contact-info-inner ul{margin: 0;padding: 0;list-style: none;}
.rolo-contact-info-inner ul li{margin-bottom: 30px;}
.rolo-contact-info-inner ul li:last-child{margin-bottom: 0;}
.rolo-contact-info-inner ul li h3{font-size: 16px;line-height: 21px;font-weight: bold;color: #fff;margin-bottom: 4px;}
.rolo-contact-info-inner ul li a{font-size: 12px;line-height: 17px;font-weight: 400;margin-bottom: 0;color: #fff;display: block;}
.rolo-logo-contact{width: 150px;}
.rolo-contact-info-inner{width: calc(100% - 175px);}
.rolo-body-main{padding-left: 0;padding-right: 0;}
.rolo-container{max-width: 1630px;margin: 0 auto;padding: 0 15px;}
/* address section css end */

@media (min-width:768px){
    .rolo-container{max-width: 720px;}
}
@media (min-width:992px){
    .rolo-container{max-width: 960px;}
}

@media (min-width:1200px){
    .rolo-container{max-width: 1140px;}
}

@media (min-width:1400px){
    .rolo-container{max-width: 1320px;}
}

@media (min-width:1681px){
    .rolo-container{max-width: 1630px;}
    .rolo-logo-contact{width: 250px;}
    .rolo-contact-info-inner{width: calc(100% - 250px);}
    .rolo-contact-info{gap: 0px;}
}



@media (max-width:1199px){
    .contact_right_box { min-width: auto;}
    .contact_grid_item1,.contact_grid_item2{width: 50%;}
    .rolo-body-main .contact_grid {display: flex;flex-wrap: wrap;}
    .contact_grid_item2{padding-left: 40px;}
    .rolo-logo-contact,.rolo-contact-info-inner{width: 100%;}
    .rolo-contact-info{width: calc(100% - 40px);}
}

@media (max-width:991px){
    .contact_right_box { min-width: 30.5rem;}
}
@media only screen and (max-width: 850px){
    .contact_text{
        padding-left:1.25rem;
        padding-top: 3.75rem;
    }
    .contact_heading{
        padding-left:0;
        line-height: 120%;
        font-size:1.75rem;
        padding-top: 1.6875rem;
        padding-bottom: 2.8125rem;
    }
    .contact_grid_item1{
        padding: 3.125rem 1.25rem 2.5rem;
        width:auto;
    }
    .contact_body{
        padding: 0;
    }
    .form_sections{
        width:100%;
    }
    .display_flex{
        display:block;
    }
    .contact_grid{
        grid-template-areas: 'one' 'two';
    }
    .contact_grid_item2{
        padding-left: 0;
        width:auto !important;
    }
    .contact_right_box{
        padding:1.8125rem 2.692rem 2.5rem 1.25rem;
        min-width: auto;
    }
    .contact_right_subheading{
        width:auto;
    }
    .contact_right_heading{
        line-height: 100%;
    }
    .rolo-contact-info{position: static;margin-top: 20px;width: 100%;}
    .contact_grid_item1,.contact_grid_item2{width: 100%;}
    .contact_grid_item1{padding-left: 0;padding-right: 0;}
}

@media (max-width:480px){
    .rolo-logo-contact,.rolo-contact-info-inner{width: 100%;}
}


