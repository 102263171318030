.left_item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 150%;
  color: rgb(255, 255, 255);
  opacity: 0.5;
  display: flex;
  align-items: center;
  height: 100%;
}

.left_item:hover {
  opacity: 1;
  cursor: pointer;
  text-decoration: none !important;
  color: white;
}

.right_item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255);
  opacity: 0.5;
}

.right_item:hover {
  opacity: 1;
  cursor: pointer;
  text-decoration: none !important;
  color: white;
}
.active_tab {
  opacity: 1;
}

nav {
  display: flex;
  align-items: center;
  padding: 2em 5%;
  padding-left: 10em;
  padding-right: 7.0625rem;
  background-color: #253746;
  color: white;
}

.rulo_logo {
  margin-right: 4.25em;
  height: 1.5rem;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.ham-menu-item {
  text-align: right;
  padding-right: 5%;
  text-decoration: none;
  color: white;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 1.25rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;

  line-height: 182%;
}
.ham-menu {
  background-color: #253746;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s;
}
.check_button{text-decoration: none;font-family: "Roboto";font-style: normal;font-weight: 700;font-size: 1rem;background: #307FE2;border: 1px solid #307FE2;border-radius: 0.25em;color: white;padding: 4px 12px;opacity: 1;}
.orange-button{padding: 6px 12px;}

@media only screen and (max-width: 850px) {
  nav {
    padding-left: 1.25em;
    padding-right:1.25rem
  }
}
NavLink:hover {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
