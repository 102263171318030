.links_flex_row {
    display: flex;
    flex-direction: row;
    padding-top: 13.3125rem;
    padding-bottom: 3.8125rem;
}
.link_left_section {
    min-width: max(375px, 22.395vw);
}
.left_section_links {
    display: flex;
    flex-direction: column;
    padding-left: 28.31%;
}
.links_left_sec_header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: #253746;
}
.links_left_section_second_row {
    margin-left: 2.25rem;
    margin-top: 1.5rem;
    color: #5B6770;
    font-weight: 400;
}
.left_section_sub_text_links {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 0.875rem;
    line-height: 200%;
    padding: 0.25em 0;
    padding-left: 1.25rem;
    border-left: 1px solid rgba(91, 103, 112, 0.5);
    height: 100%;
    cursor: pointer;
}
.links_parent {
    flex-grow: 1;
    padding-right: 18.125rem;
}
.selected_section_links {
    border-left: 2px solid #FF6900;
    color: #253746;
    font-weight: 500;
}
.link_heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 120%;
    color: #253746;
}
.link_date{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 86%;
    color: #253746;
}
.heading_align{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.links_text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: #253746;
    padding-top: 1.75rem;
}

.links_text p{
    font-family: inherit;
}
.section_mobile{
    display: none;
}

@media only screen and (max-width:850px){
    .links_flex_row{
        flex-direction: column;
        padding-top: 2.0625rem;
    }
    .left_section_links{
        padding-left: 1.25rem;
    }
    .mobile_banner{
        padding-top: 3rem;
        padding-bottom: 2.5rem;
    }
    .mobile_banner_txt_heading{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 120%;
        color: #FFFFFF;
    }
    .mobile_banner_date_font{
        font-size: 1rem;
        line-height: 150%;
    }
    .links_parent{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 3.75rem;
    }
    .heading_align{
        display: none;
    }
    .links_left_section_second_row{
        margin-top: 1.125rem;
        margin-left: 2.25rem;
    }
    .left_section_sub_text_links{
        border-left:none;
        padding-left: 0;
    }
    .selected_section_links{
        border-left:none;
    }
    .links_text{
        padding-top: 0;
    }
    .section_mobile{
        display: block;
        background: #253746;
    }
    .pt_2_4375{
        padding-top: 2.4375rem;
    }
}