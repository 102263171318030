.flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex_column {
  display: flex;
  flex-direction: column;
}

.orange-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  background: #ff6900;
  border: 1px solid #f36d21;
  border-radius: 0.25em;
  color: white;
  padding: 0.25em 0.75em 0.25em 0.3px;
}

@media only screen and (min-width: 850px) and (max-width: 1600px) {
  * {
    font-size: 12px;
  }
}
/* @media only screen (min-width: 1200px) and (max-width: 1600px) {
  * {
    font-size: 16px;
  }
} */
@media only screen and (min-width: 2000px) {
  * {
    font-size: 24px;
  }
}
