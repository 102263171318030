.blogpost_flex_row {
    display: flex;
    flex-direction: row;
    padding-top: 4.1875rem;
    padding-bottom: 1.8125rem;
    background-color: #253746;
}

.blogpost_left_section {
    min-width: max(375px, 22.395vw);
}

.blogpost_left_sec_header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    /* or 24px */


    color: #FFFFFF;
    /* margin-left: 1.3em; */

}

.blogpost_title {
    padding-left: 10rem;
    padding-bottom: 3.9375rem;

    background-color: #253746;

}

.blogpost_title label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 120%;
    /* or 58px */


    color: #FFFFFF;
    width: 69.27vw;
}

.left_section_blogpost {
    display: flex;
    flex-direction: column;
    padding-left: 10rem;
    margin-left: -2.5rem;
}

.blogpost_content_container {
    padding: 0 10rem;
    padding-top: 4.6875rem;
    padding-bottom: 2.75rem
}

.blogpost_content_grid {
    display: grid;
    grid-template-areas: 'two one';
}

.blogpost_grid_item1 {
    grid-area: one;
}

.blogpost_grid_item2 {
    grid-area: two;
    width: 49.21875vw;
    padding-right: 9.6875rem;
}

.blogpost_content_sub_heading {
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1.875rem;

}

.blogpost_content_para p{


    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 1.875rem;
}
.blogpost_grid_item1 img{
    width:100%
}

@media (max-width:850px) {
    .blogpost_flex_row {
        flex-wrap: wrap;
        padding: 2.5rem 1.25rem;
    }

    .blogpost_left_section {
        min-width: 100%
    }

    .left_section_blogpost {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 0px;
        margin-left: 0px;

    }

    .blogpost_title {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 1.75rem;
    }

    .blogpost_title label {
        width: 100%;
        font-size: 1.75rem;
    }
    .blogpost_content_grid{
        grid-template-areas: 'one' 'two';
    }
    .blogpost_content_container {
        padding: 0;
        padding-bottom: 1rem;

    }
    
    .blogpost_grid_item2{
        padding: 0 1.25rem;
        padding-top: 2.5rem;
        width: 100%
    }
    .blogpost_content_sub_heading{
        font-size: 1.25rem;
    }
   
    
}