.bg-color-blue {
    background-color: #307FE2;

}

.left_section {
    width: max(375px, 22.395%);
}

.pl-28per {
    padding-left: 10rem;
    padding-top: 4rem;


}

.contact {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: #FFFFFF;
}

.right_section {
    padding: 3.9em 0;
    min-width: 200px
}

.right_section_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 100%;
    color: #FFFFFF;
    padding-bottom: 2.5rem;
}

.pd-6em {
    padding-right: 6em;
}

.footerTopograpy {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.footer-item1 {
    padding-left: 10em;
    margin-top:auto;
    margin-bottom:auto;

}

.footer-item2 {
    padding-left:13.75rem;
    margin-top:auto;
    margin-bottom:auto;
}

.footer-item3 {
    background-color: #FF6900;
    display: flex;
    justify-content:end ;
    padding: 2em 0px;
    padding-right: 3.625em;
    /* min-width: 32.5em;
    margin-right:10em */
    min-width: min(27.0833vw,580px);
    margin-right:10em

}

.footer-item4 {}
/* @media only screen and (min-width:850px) and (max-width:1600px) {
    *{
        font-size:12px
    }
}
@media only screen and (min-width:2000px)  {
    *{
        font-size:24px
    }
} */
.pl-28per div{
    margin-left: -2.25rem;
}

@media only screen and (max-width:850px) {
    .pl-28per {
        padding-left: 1.125rem;
        padding-top: 2.5em;
    }
    .pl-28per div{
        margin-left: 0;
    }

    .right_section {
        padding-left: 1.125rem;
        padding-top: 2em;
        padding-right: 0.25em;
    }

    .right_section_text {
        font-size: 1.5rem;
    }

    .pd-6em {
        padding-right: 3em;
    }
    .footer-item3{
        min-width: 100%;
        justify-content: center;
        padding-right: 0;
    }
    .footer-item1{
        padding-left: 1.125rem;
        padding-right: 0.25em;
        padding-top: 3em;
    }
    .footer-item2{
        padding-left: 1.25em;
        padding-right: 0.25em;
        padding-bottom: 3em;
    }
    .mycol{
        flex-direction: column;
    }
}
@media only screen and (min-width: 851px) and (max-width:1235px) {
    
    .footer-item2{
        padding-left: 3rem;
       
    }
    .footer-item3{
        margin-right: 3rem;
    }
   
}

@media only screen and (min-width: 851px) and (max-width:965px) {
    .footer-item3{
        margin-right: 0rem;
        min-width: auto;
        padding-left:3.625em ;
    }
}