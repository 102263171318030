.blog_flex_row {
    display: flex;
    flex-direction: row;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
}

.no_padding {
    padding-top: 0px;
    padding-bottom: 0px;
}

.blog_left_section {
    min-width: max(375px, 22.395vw);
}

.blog_padding_top_5rem {
    padding-top: 3.75rem;
}

.blog_left_sec_header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;

    color: #253746;
    /* margin-left: 1.3em; */

}

.color-white {
    color: #ffffff;
}

.blog_banner_right {
    flex-grow: 1;
}

.blog_banner_right_side {

    background-image: url('../../assets/Home/home_blog_desktop_bg.png');
    background-size: cover;
    width: 100%;
    height: 32.5625rem;
}

.blog_banner_text_box {
    position: absolute;
    inset: 0px;
    left: 58.38%;
    top: 0%;
    padding-left: 2.625rem;
    padding-right: 1.8125rem;
    width: min(28.75rem, 23.95vw);
    background: #253746;
    padding-top: 1.8125rem;
    height: fit-content;

}

.blog_banner_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
    color: #FFFFFF;
    width: 90%
}

.blog_banner_date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    color: #FFFFFF;

    margin-top: 1.5rem;


}

.left_section_blog {
    display: flex;
    flex-direction: column;
    padding-left: 28.31%;
}

.blog-banner-button-pd {
    padding-right: 7.5rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.blog_grid_container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5625rem;
    row-gap: 1.1875rem;

}

.blog_grid_section {
    padding: 0 10.25rem;
    padding-top: 5.3125rem;
    padding-bottom: 7.5rem;
    background-color: #F1F3F4;
}

.blog_grid_item {
    padding: 2.5rem 0;
    padding-left: 1.25rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background-color: #FFFFFF;
    border-bottom: 1px solid #253746;
    cursor: pointer
}

.blog_grid_date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    color: #5B6770;
}

.blog_grid_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
    color: #307FE2;

}

.blog_grid_sub_heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #253746;

}

.blog_load_more_button {
    margin-top: 4.875rem;
    padding: 0.5rem 1.25rem;
    border: 1px solid #F36D21;
    border-radius: 0.25rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 21px;
    /* identical to box height, or 131% */


    color: #FF6900;
    text-align: center;
    width: fit-content;
    cursor: pointer;
}

@media (max-width:850px) {
    .blog_left_section {
        min-width: 100%
    }

    .left_section_blog {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.25rem;

    }

    .blog_right_side {
        margin-top: 2rem
    }

    .blog_banner_right_side {
        width: 100%;
        background-image: url('../../assets/Home/home_blog_bg.png');
        aspect-ratio: 333 / 211;
        height: auto;
    }

    .blog_banner_text_box {
        position: static;
        padding-left: 1.25rem;
        padding-right: 0px;
        width: 100%;
    }

    .blog_banner_title {
        font-size: 1rem;
    }

    .blog_banner_date {
        text-align: left;
        font-size: 0.875rem;
        margin-bottom: 0px;
        margin-top: 1rem
    }

    .blog_flex_row {
        flex-wrap: wrap;

    }

    .blog-banner-button-pd {
        padding-top: 0;
    }
    .blog_grid_section{
        padding: 0 1.25rem;
        padding-top:2.4375rem;
        padding-bottom: 3.75rem;

    }
    .blog_grid_container{
        grid-template-columns: 1fr;
        row-gap: 1.3125rem;
    }
}