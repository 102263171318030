.home_banner::after {
  position: absolute;
  inset: 0;
  height: 100%;
  /* background-color: rgba(37, 55, 70); */
  opacity: 0.8;
  z-index: -1;
  left: 0px;
  content: "";
}

.home_banner {
  position: relative;
  background-image: url("../../assets/homebg01.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 59% 30%;
  z-index: 1;
  min-height: 50rem;
  display: flex;
}

.home_banner.slider01{
  background-image: url("../../assets/homebg03.png");
}
.home_banner.slider01::after{
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 20.7%, rgba(0, 0, 0, 0.8) 57.86%, rgba(0, 0, 0, 0.8) 97.27%);
}

.home_banner.slider01 .banner-title{
  width: 730px;
}
.home_banner.slider01 .banner-title span{color: #307fe2;}

.home_banner.slider02{
  background-image: url("../../assets/homebg02.png");
}

.home_banner.slider02::after{
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 20.7%, rgba(0, 0, 0, 0.8) 57.86%, rgba(0, 0, 0, 0.8) 97.27%);
}

.home_banner.slider02 .banner-title{
  max-width: 614px;
}
.home_banner.slider02 .banner-title span{display: block;}
.home_banner.last-banner .home-banner-grid-item-2 .home_banner_ipad{width: 100%;max-width: 777px; height: 400px;line-height: 0;}
.home_banner.last-banner .home-banner-grid-item-1 .banner-title{max-width: 630px;}
.home_banner.last-banner .home_banner_grid{width: 100%;}


.home_banner_grid {
  display: grid;
  grid-template-areas: "one two" "three two";
  z-index: 2;
  padding-left: 10em;
  overflow: hidden;
}

.home-banner-grid-item-1 {
  grid-area: one;
}

.home-banner-grid-item-2 {
  grid-area: two;
}

.home-banner-grid-item-2-content {
  height: 100%;
  display: flex;
  align-items: center;
}

.home-banner-grid-item-3 {
  grid-area: three;
}

.banner-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 100%;
  width: 99%;
  /* identical to box height, or 64px */

  color: #ffffff;
  /* padding-top: 4em; */
  padding-bottom: 0.3em;
}

.home-banner-grid-item-1 p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%;
  color: white;
  max-width: 595px;
}
.home-banner-grid-item-1 p:nth-child(2){margin-bottom: 1em;}

.home-banner-grid-item-1 .banner-title span {
  font-size: inherit;
  color: #ff6900;
}

.home-banner-grid-item-1 p span {
  font-style: italic;
  color: #ff6900;
  font-size: 1.25rem;
}

.home-banner-grid-item-1 .banner-title span:nth-child(2){
  display: block;
  color: #2A7DE1;
}

.home_banner_iphone {
  position: absolute;
  z-index: 100;
  inset: 0px;
  left: 67.77%;
  top: 29.8%;
  width: 16.25%;
}

.home_banner_rulo_dashboard {
  position: absolute;
  z-index: 10;
  inset: 0px;
  left: 25.1%;
  top: 21.88%;
  height: 55.42%;
}

.home_banner_4s {
  position: absolute;
  z-index: 100;
  inset: 0px;
  top: 52.42%;
  left: 10%;
  width: 24.62%;
}

.home-banner-button-pd {
  padding: 0.38em 0.75em 0.38em 0.3px;
  padding-right: 7rem;
  margin-top: 0.875rem;
}

.home-banner-button-text-below {
  padding-top: 1.25em;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.75);
  /* margin-bottom: 12rem; */
}

.home_banner_ipad {
  width: 55.88vw;
}

.rolo-hero-banner .swiper-pagination{bottom: 60px;left: 24%;}
.rolo-hero-banner .swiper-pagination .swiper-pagination-bullet{width: 15px;height: 15px;border: 1px solid rgba(255, 255, 255, 0.5);background: transparent;opacity: 1;margin: 0 12px;}
.rolo-hero-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{border:1px solid #FF6900;background-color: #FF6900;}

.home_left_sec_header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;

  color: #253746;
  /* margin-left: 1.3em; */
}

.left_section_home {
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
}

.left_section_sub_text_home {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 150%;
  color: #5b6770;
  margin-right: 0.5rem;
  /* margin-top: 1rem; */
}

.home_sys_over_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 100%;
  color: #253746;
  padding-bottom: 2em;
}

.home_sys_over_title span {
  font-size: inherit;
  color: #307fe2;
}

.home_sys_over_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 92%;
  margin-left: -5.9%;
}

.home_sys_over_grid div {
  display: flex;
  flex-direction: column;
}

.home_sys_over_grid div img {
  height: 3rem;
}

.home_sys_over_grid div label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  /* or 29px */

  text-align: center;

  color: #253746;
  padding: 2em;
}

.home_flex_row {
  display: flex;
  flex-direction: row;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.home_flex_column {
  display: flex;
  flex-direction: column;
}

.home_left_section {
  min-width: max(375px, 22.395vw);
}

.home_left_section_second_row {
  margin-top: 1rem;
}

.home_industry_tile {
  background-repeat: no-repeat;
  /* background-image: var(--bg-image); */
  aspect-ratio: 385/455;
  height: 28.4375rem;
  border-radius: 0px 0px 1.25rem 0px;
  background-size: cover;
  padding-top: 1.5rem;
  padding-left: 1.875rem;
  padding-right: 2.25rem;
  position: relative;
  z-index: 1;
  margin-bottom: 1.3125rem;
}

.home_industry_tile::after {
  content: "";
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #253746 0%,
    #253746 0.01%,
    rgba(37, 55, 70, 0) 100%
  );
  z-index: -2;
}

.home_industry_tile::before {
  position: absolute;
  inset: 0px;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #307fe2 49.48%,
    rgba(48, 127, 226, 0) 100%
  );
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
}

.home_industry_tile_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 100%;
  padding-top: 6.25rem;
  padding-bottom: 1rem;

  color: #ffffff;
}

.home_industry_tile_desc {
  opacity: 0;
  transition: all ease 0.5;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */

  color: #ffffff;
  transition: all 0.5s ease;
}

.home_industry_tile:hover::before {
  opacity: 1;
  height: 100%;
}

.home_industry_tile:hover .home_industry_tile_desc {
  opacity: 1;
}

.home_industry_parent {
  flex-grow: 1;
  margin-right: 9.875rem;
  overflow-x: scroll;
}

.home_industry_parent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.home_industry_parent::-webkit-scrollbar {
  height: 0.25rem;
  background-color: #f5f5f5;
}

.home_industry_parent::-webkit-scrollbar-thumb {
  background-color: #ff6900;
}

.home_benefits_tab_row {
  display: flex;
  flex-direction: row;
  gap: 3.25rem;
  border-bottom: 1px solid rgb(91, 103, 112);
  overflow: scroll;
  max-width: 90vw;
  user-select: none;
  z-index: 5;
  isolation: isolate;
  scroll-behavior: smooth;
}
.home_benefits_tab_row::-webkit-scrollbar {
  display: none;
}

.home_benefits_tab_row::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.home_benefits_tab_row::-webkit-scrollbar {
  height: 3px;
  background-color: #f5f5f5;
}

.home_benefits_tab_row::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.home_benefits_right_side {
  flex-grow: 1;
  margin-right: 9.875rem;
}

.home_benefits_tab {
  position: relative;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  color: #5b6770;
  padding-bottom: 0.75rem;
  cursor: pointer;
  text-align: center;
  user-select: none;
}

.home_benefits_tab::after {
  position: absolute;
  width: 0%;
  inset: 0px;
  /*top: 120%;*/
  border-bottom: 2px solid #ff6900;
  content: "";
  transition: all 0.5s;
  z-index: 1000;
}

.home_benefits_tab:hover::after {
  width: 100%;
}

.home_benefits_tab_active {
  font-weight: 500;
  color: #253746;
}

.home_benefits_tab_active:after {
  position: absolute;
  width: 100%;
  inset: 0px;
  /*top: 120%;*/
  border-bottom: 2px solid #ff6900;
  content: "";
  transition: all 0.5s;
}

.home_benefits_content_tab {
  display: grid;
  grid-template-areas: "two one";
  /* margin-top: 3rem; */
  grid-template-columns: auto 1fr;
}

.home_benefits_tab_grid_item1 {
  grid-area: one;
  width: 100%;
}

.home_benefits_tab1_img {
  position: absolute;
  inset: 0px;
  top: 4.861%;
  left: 2.3%;
  width: 95.46%;
}

.home_benefits_tab1_bg {
  width: 100%;
}

.home_benefits_tab2_img {
  position: absolute;
  inset: 0px;
  top: 43.04%;
  left: 54.162%;
  width: 41.135%;
}

.home_benefits_tab_grid_item2 {
  grid-area: two;
  width: 20vw;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;
  color: #253746;
  margin-top: 2.9375rem;
}

.home_benefits_tab3_margin_right {
  margin-right: 6.4375rem;
}

.home_benefits_tab2_margin_right {
  margin-right: 1.25rem;
}

.home_benefits_tab1_margin_right {
  margin-right: 5rem;
}

.color-white {
  color: #ffffff;
}

.no_padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.home_padding_top_5rem {
  padding-top: 3.75rem;
}

.home_blog_right_side {
  background-image: url("../../assets/Home/home_blog_desktop_bg.png");
  background-size: cover;
  width: 100%;
  height: 32.5625rem;
}

.home_blog_text_box {
  position: absolute;
  inset: 0px;
  left: 58.38%;
  top: 0%;
  padding-left: 2.625rem;
  padding-right: 1.8125rem;
  width: min(28.75rem, 23.95vw);
  background: #253746;
  padding-top: 1.8125rem;
  height: fit-content;
}

.home_blog_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 120%;
  color: #ffffff;
  width: 90%;
}

.home_blog_date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: #ffffff;
  text-align: right;
  margin-top: 3.3125rem;
  margin-bottom: 2rem;
}

.home_blog_right {
  flex-grow: 1;
}

.home_company_padding {
  padding-bottom: 4.125rem;
  padding-right: 18.81vw;
  overflow: hidden;
}
/* .home_company_animation{
    transition: all 1s;
}
.home_company_animation:hover{
    animation: move-out 1s forwards
} */
@keyframes move-out {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
.home_company_grid {
  display: grid;
  grid-template-areas: "two one";
  min-height: 32.25rem;
  grid-template-columns: auto 1fr;
  padding-right: 3.75rem;
}

.home_company_grid_item2 {
  grid-area: two;
  width: max(15.375rem, 26.98vw);
  display: flex;
  align-items: center;
}
.home_company_grid_item1 {
  grid-area: one;
  /* margin-left: 2.1875rem; */
  display: flex;
  align-items: center;
}
.home_company_dot_tabs {
  margin-top: 2.815rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.home_company_grid_item1 img {
  width: 100%;
  min-width: 15.5rem;
  padding-left: 6.09vw;
}

.home_company_tab_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 120%;
  color: #253746;
}
.home_company_rulo_title{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 120%;
  color: #253746;
}

.home_company_sub_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 150%;
  color: #5b6770;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.home_company_tab_desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #5b6770;
}
.animation_fade_out {
  animation: fade-out 0.5s forwards;
}
.animation_fade_in {
  animation: fade-in 0.5s forwards;
}

.display-none {
  display: none;
}
.display-block {
  display: grid;
}
.display-block-opacity-0 {
  display: grid;
  opacity: 0;
}
.display-grid {
  display: grid;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
  }
}
.home_company_width {
  width: 59.52vw;
}

.home_margin_left {
  margin-left: -2.25rem;
}
.home_benefits_tab_header_shader {
  position: absolute;

  height: 90%;
  width: 3.9375rem;
  z-index: 10000;
}
.left_shader {
  left: 0%;
  background: linear-gradient(
    90deg,
    rgb(231, 233, 234) 52.08%,
    rgba(232, 231, 234, 0) 100%
  );
}
.right_shader {
  right: 0%;
  background: linear-gradient(
    90deg,
    rgba(231, 233, 234, 0) 0%,
    rgb(231, 233, 234) 52.08%
  );
}

.home_banner.last-banner .home_banner_iphone{width: 14.25%;z-index: -1;}

/* testimonails section css start */
.rolo-testi{background-color:#253746;padding-top:120px;padding-bottom: 105px;}
.rolo-testi-box{padding: 40px;border:1px solid #F1F3F4;height: 100%;position: relative;}
.rolo-testi__container .swiper-slide.swiper-slide-active .rolo-testi-box{background-color: #307fe2;border:1px  solid #307fe2;min-height: 400px;padding-bottom: 116px;}
.rolo-testi-box p{color: #F1F3F4;font-size: 24px;line-height: 150%;font-weight: 400;margin-bottom: 24px;}
.rolo-custo-name{color: #F1F3F4;font-size: 28px;font-weight: 500;line-height: 150%;margin-bottom: 4px;}
.rolo-custo-desig{color: #F1F3F4;font-size: 20px;font-weight: 400;line-height: 30px;}
.rolo-testi__container .swiper-wrapper{align-items: center;padding-bottom: 60px;}
.rolo-testi__container .swiper-pagination{left: auto;bottom: 0;}
.rolo-testi__container .swiper-wrapper .swiper-slide-next .rolo-testi-box p,.rolo-testi__container .swiper-wrapper .swiper-slide-prev .rolo-testi-box p{overflow: hidden;display: -webkit-box; -webkit-line-clamp: 4;line-clamp: 4; -webkit-box-orient: vertical;}
.rolo-testi__container .swiper-slide.swiper-slide-active .rolo-testi-box .rolo-custo-content{position: absolute;width: 100%;bottom: 40px;}
/* testimonails section css end  */

@media (min-width:1601px){
   .home_banner.last-banner .home-banner-grid-item-2 .home_banner_ipad {height: 550px;}
}

@media (max-width: 850px) {
  .home_margin_left {
    margin-left: 0;
  }
  .home_banner {
    min-height: 40rem;
  }
  .home_banner_grid {
    padding-left: 1.125em;
    grid-template-areas: "one" "two" "three";
  }

  .banner-title {
    font-size: 3rem;
    padding-top: 1.3em;
    width: 100%;
  }

  .home-banner-grid-item-1 p {
    font-size: 1rem;
  }

  .home_benefits_tab {
    /* min-width: 12.7rem; */
    white-space: nowrap;
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
  }

  .home_banner_ipad {
    width: 100%;
    height: auto;
  }

  .home_banner_iphone {
    display: none;
  }

  .home-banner-button-text-below {
    margin-bottom: 2rem;
  }

  .home_flex_row {
    flex-wrap: wrap;
    padding: 2.5rem 1.25rem;
  }

  .home_left_section {
    min-width: 100%;
  }

  .left_section_home {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
  }

  .home_left_section_second_row {
    margin: 0px;
  }

  .home_sys_over_title {
    font-size: 1.5rem;
  }

  .home_sys_over_grid {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0px;
  }

  .home_sys_over_grid div label {
    font-size: 1.25rem;
  }

  .home_sys_over_grid div img {
    height: 2.25rem;
  }

  .home_right_side {
    margin-top: 2rem;
  }

  .home-banner-grid-item-1 p span {
    font-size: 0.875rem;
  }

  .home_industry_parent {
    margin-right: 0px;
  }

  .home_industry_tile {
    height: 24.75rem;
    padding-left: 1.25rem;
  }

  .home_benefits_content_tab {
    grid-template-areas: "one" "two";
  }

  .home_benfits_tab {
    min-width: 12.7rem;
  }

  .home_benefits_tab1_bg {
    width: 90vw;
    gap: 2rem;
  }

  .home_benefits_tab_row {
    width: 90vw;
  }

  .home_benefits_tab_grid_item2 {
    margin-top: 1rem;
    font-size: 1.25rem;
    width: 100%;
  }

  .home_benefits_tab2_custom {
    margin-top: 3rem;
  }

  .home_blog_right_side {
    width: 100%;
    background-image: url("../../assets/Home/home_blog_bg.png");
    aspect-ratio: 333 / 211;
    height: auto;
  }

  .home_benefits_tab1_img {
    width: 102%;
  }

  .home_blog_right {
    width: 100%;
  }

  .home_blog_text_box {
    position: static;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }

  .home_blog_title {
    font-size: 1rem;
  }

  .home_blog_date {
    text-align: left;
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }
  .home_company_grid {
    grid-template-areas: "one" "two";
    padding-right: 0;
  }
  .home_company_grid_item1 {
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  .home_company_grid_item2 {
    width: 100%;

    margin-top: 2.25rem;
  }
  .home_company_tab_title {
    font-size: 1.5rem;
  }
  .home_company_rulo_title{
    font-size: 1.5rem;
  }
  .home_company_sub_title {
    font-size: 1.25rem;
  }
  .home_company_tab_desc {
    font-size: 0.875rem;
  }
  .home_company_grid_item1 img {
    max-width: 400px;
    padding-left: 0px;
  }

  .home_company_width {
    width: 100%;
  }
  .rolo-testi{padding: 40px 20px;}
  .home-banner-grid-item-2{display: none;}

  .home_banner  .home-banner-grid-item-3,.home_banner  .home-banner-grid-item-3{grid-area: unset;}
  .home_banner.last-banner .home-banner-grid-item-1 {
    max-width: 100% !important;
  }
}



@media (max-width:1600px){
  .rolo-hero-banner .swiper-pagination {bottom: 40px;}
  .rolo-testi__container .swiper-pagination{left: auto;bottom: 0;}
}
@media (max-width:1399px){
  .rolo-testi-box p {font-size: 20px;}
  .rolo-custo-name{font-size: 22px;}
  .rolo-custo-desig{font-size: 18px;}
  .rolo-testi {padding-top: 60px;padding-bottom: 60px;}
}
@media (max-width:1280px){
  .home_banner.last-banner .home-banner-grid-item-2 .home_banner_ipad {height: 330px;}
  .home_banner.last-banner .home-banner-grid-item-1 {max-width: 430px;}
}
@media (max-width:1199px){
  .banner-title{font-size: 3rem;}
  .rolo-testi-box p {font-size: 18px;}
  .rolo-custo-name{font-size: 20px;}
  .rolo-custo-desig{font-size: 16px;}
  .rolo-testi__container .swiper-slide.swiper-slide-active .rolo-testi-box{min-height: auto;}
  .home_banner.slider01 .banner-title span{display: block;}
  .home_banner.last-banner .home-banner-grid-item-1 {max-width: 330px;}
}

@media (max-width:991px){
  .home_banner.last-banner .home-banner-grid-item-1 {
    max-width: 263px;
}
.home_left_section {
  min-width: max(310px, 19.395vw);
}
}

@media (max-width:767px){
  .home_banner.slider01 .banner-title {
    width: 100%;
    max-width: 730px;
}
.home_banner {
  min-height: 32rem;
}

}

@media (max-width:480px){
  .banner-title{font-size: 2.12rem;max-width: 67%;padding-top: 0;}
  .home_banner.last-banner .home-banner-grid-item-1 .banner-title {max-width: 67%;}
  .home_banner.slider02 .banner-title {max-width: 73%;}
  .rolo-hero-banner .swiper-pagination {bottom: 50px;left: 0;}
  .rolo-testi__container .swiper-pagination{left: auto;bottom: 0;}
  .home-banner-button-pd{margin-bottom: 1em;}
  .rolo-testi-box {padding: 14px;}
  .rolo-testi__container .swiper-slide.swiper-slide-active .rolo-testi-box .rolo-custo-content{bottom: 14px;}
  .home-banner-grid-item-1 p {padding-right: 18px;}
  .home_banner {
    min-height: 580px;
  }
  .home-banner-grid-item-1 p:nth-child(2){margin-top: 20px;margin-bottom: 30px;}
  .home-banner-button-pd{margin-top: 34px;}
  .home_banner.slider01 {
    background-position: 80% 34%;
}
.home_banner.slider01 .banner-title {
  max-width: 326px;
}
.home_banner.slider01::after,.home_banner.slider02::after {
  background: linear-gradient(270deg, rgb(0 0 0 / 84%) 20.7%, rgba(0, 0, 0, 0.8) 57.86%, rgba(0, 0, 0, 0.8) 97.27%);
}
.rolo-testi__container .swiper-slide.swiper-slide-active .rolo-testi-box,.rolo-testi__container .swiper-slide .rolo-testi-box {
min-height: 426px;
}
}
